import React from "react";
import dynamic from 'next/dynamic';
import classNames from "classnames";
import { makeStyles } from "@material-ui/core/styles";

const Footer = dynamic(() => import('/components/Footer/Footer.js'), { ssr: true });
import HeaderSection from "pages-sections/HeaderSection.js";

import landingPage from "/styles/jss/bbhost/pages/landingPage.js";
import teamStyle from "/styles/jss/bbhost/pages/landingPageSections/teamStyle.js";
const styles = {
  ...landingPage,
  ...teamStyle
};
const useStyles = makeStyles(styles);

export default function Custom404() {
  const classes = useStyles();
  return (
    <div>
      <HeaderSection name="404" />
      <div className={classNames(classes.main, classes.mainRaised)}>
        <div className={classes.container}>
          <div className={classes.section}>
          </div>
        </div>
      </div>
      <Footer />
    </div>
  );
}